import React, { useMemo } from 'react';
import { Button, Collapse, StyleRulesCallback, Theme, withStyles } from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import { UserRole } from 'application/types/users';
import UserSearchScopesSelect from './UserSearchScopesSelect';
import UserAccountStatus from '../../../../shared/domain/user/profile/UserAccountStatus';
import SpecialtyName from '../../../../shared/core/doctor/domain/SpecialtyName';

export const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
  container: {
    paddingTop: theme.spacing(2)
  },
  selectOption: {
    paddingTop: 0,
    paddingBottom: 0
  },
  closeButton: {
    float: 'right',
    marginTop: theme.spacing(2)
  }
});

interface Scope {
  managerIds?: string[];
  groupIds?: string[];
  statuses?: string[];
  specialties?: string[];
  planIds?: string[];
  facilityIds?: string[];
}

interface Props {
  classes?: any;
  isOpened: boolean;
  activeScopes: Scope;
  onScopeClose: () => void;
  onScopeChange: (updatedScope: Scope) => void;
}

interface FetchSearchParamsQuery {
  plans: Array<{
    id: string;
    name: string;
  }>;

  facilities: Array<{
    id: string;
    name: string;
    number: string;
  }>;

  groups: Array<{
    id: string;
    name: string;
    number: string;

    owners: Array<{
      id: string;
      fullName: string;
    }>;

    managers: Array<{
      id: string;
      fullName: string;
      userRole: UserRole;
    }>;
  }>;
}

const SEARCH_PARAMS_QUERY = gql`
  query FetchSearchParams {
    plans {
      id
      name
    }

    facilities(query: { favorite: true }, limit: 10000) {
      id
      name
      number
    }

    groups {
      id
      name
      number

      owners {
        id
        fullName
      }

      managers {
        id
        fullName
        userRole
      }
    }
  }
`;

export const UserSearchScopes: React.FunctionComponent<Props> = ({
  classes,
  isOpened,
  activeScopes,
  onScopeClose,
  onScopeChange
}: Props) => {
  const { data } = useQuery<FetchSearchParamsQuery>(SEARCH_PARAMS_QUERY);

  const groupManagerOptions = useMemo(() => {
    const usersMap = data?.groups.reduce((usersMap, group) => {
      group.managers.forEach((user) => {
        if (user.userRole === UserRole.DOCTOR) return;
        usersMap.set(user.id, user.fullName);
      });

      group.owners.forEach((user) => {
        usersMap.set(user.id, user.fullName);
      });

      return usersMap;
    }, new Map());

    if (!usersMap) return {};

    return Object.fromEntries(usersMap);
  }, [data]);

  const handleChange = (key: string, values: string[]) => {
    onScopeChange({
      ...activeScopes,
      [key]: values.length ? values : []
    });
  };

  return (
    <Collapse in={isOpened}>
      <div className={classes.container}>
        <UserSearchScopesSelect
          options={{
            [UserAccountStatus.ACTIVE]: 'Actif',
            [UserAccountStatus.INACTIVE]: 'Inactif',
            [UserAccountStatus.DEMO]: 'Demo',
            [UserAccountStatus.INTERNAL]: 'Interne',
            [UserAccountStatus.PAUSED]: 'Pause'
          }}
          label="Status"
          values={activeScopes.statuses}
          onChange={(statuses) => handleChange('statuses', statuses)}
        />

        <UserSearchScopesSelect
          options={{
            [SpecialtyName.ANESTHESIOLOGIST]: 'Anesthésiologiste',
            [SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST]: 'Anesthésiologiste-Intensiviste',
            [SpecialtyName.GENERAL_SURGEON]: 'Chirurgien',
            [SpecialtyName.INTERNIST]: 'Interniste',
            [SpecialtyName.ORTHOPEDIC_SURGEON]: 'Chirurgien orthopétique',
            [SpecialtyName.PNEUMOLOGIST]: 'Pneumologue'
          }}
          label="Spécialité"
          values={activeScopes.specialties}
          onChange={(specialties) => handleChange('specialties', specialties)}
        />

        <UserSearchScopesSelect
          options={
            data?.groups.reduce(
              (accumulator, group) => ({
                ...accumulator,
                [group.id]: group.name
              }),
              {}
            ) || {}
          }
          label="Groupe"
          values={activeScopes.groupIds}
          onChange={(groupIds) => handleChange('groupIds', groupIds)}
        />

        <UserSearchScopesSelect
          options={groupManagerOptions}
          label="Responsable"
          values={activeScopes.managerIds}
          onChange={(managerIds) => handleChange('managerIds', managerIds)}
        />

        <UserSearchScopesSelect
          options={
            data?.facilities.reduce(
              (accumulator, facility) => ({
                ...accumulator,
                [facility.id]: `(${facility.number}) ${facility.name}`
              }),
              {}
            ) || {}
          }
          label="Établissement principal"
          values={activeScopes.facilityIds}
          onChange={(facilityIds) => handleChange('facilityIds', facilityIds)}
        />

        <UserSearchScopesSelect
          options={
            data?.plans.reduce(
              (accumulator, plan) => ({
                ...accumulator,
                [plan.id]: plan.name
              }),
              {}
            ) || {}
          }
          label="Forfait"
          values={activeScopes.planIds}
          onChange={(planIds) => handleChange('planIds', planIds)}
        />

        <Button color="primary" className={classes.closeButton} onClick={onScopeClose}>
          Fermer
        </Button>
      </div>
    </Collapse>
  );
};

export default withStyles(styles)(UserSearchScopes);
