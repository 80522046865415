import { last, sortBy } from 'lodash';
import moment from 'moment-timezone';
import { RAMQPlaces } from 'shared/ramq/RamqPlaces';
import RAMQCodes, { TimedAttributeKey } from 'shared/ramq/RamqCodes';
import { ACTIVITIES_ACT_TYPE } from '../../../shared/collection/collectionConstants';
import { datesAreTheSameDay } from '../../../shared/utils/dateTime/dateTimeUtils';
import BillingType from '../../../shared/domain/billing/model/BillingType';
import { getFirstPlaceByPriority } from '../../containers/User/preferencesUtils';
import { codeIsInRole2 } from '../../../shared/domain/activity/act/service/actCodeUtils';
import { getStore } from '../../reduxStore/configureStore';
import { selectSpecialty } from '../../containers/User/selectors';
import SpecialtyName from '../../../shared/core/doctor/domain/SpecialtyName';

const CODE_THAT_CANT_BE_USED_AS_STARTING_POINT = [
  '911',
  '6912',
  '41038',
  '6925',
  '6913',
  '6946',
  '6098',
  '6099',
  '6917',
  '6918',
  '6920',
  '6915',
  '6928',
  '6930',
  '921',
  '910',
  '41007',
  '41033'
];

const isNotAnException = (code) => !CODE_THAT_CANT_BE_USED_AS_STARTING_POINT.includes(code.code);

export const getStartingPoint = (acts) => {
  const userSpecialty = selectSpecialty()(getStore().getState());
  const isUserSurgeon = [SpecialtyName.GENERAL_SURGEON, SpecialtyName.ORTHOPEDIC_SURGEON].includes(userSpecialty);

  if (isUserSurgeon) return getStartingPointForSurgeon(acts);

  const actList = filterOtherActivitiesAndUnusableValues(acts);

  return getLastValidAct(actList)?.end;
};

export const getLastValidAct = (acts) => {
  const usableActs = acts.filter((act) => {
    if (hasNoCode(act)) return true;
    if (hasAtLeastOneCodeInRoleTwoWithExceptions(act)) return true;
    if (hasPainDayExclusiveCode(act)) return true;

    return false;
  });

  return last(sortBy(usableActs, 'end'));
};

export const getLastActOfList = (activities, filters = [isAnAct]) => {
  const sortedActs = sortBy(activities, 'start').filter((act) => filters.every((filter) => filter(act)));
  return last(sortedActs);
};

export const getActsInContextInTheSameDay = (activities, date) =>
  activities.filter((act) => act.type === ACTIVITIES_ACT_TYPE && datesAreTheSameDay(moment(date), moment(act.date)));

export const getLastPlaceUsedInContext = (activities, placesPreference) => {
  const lastAct = getLastActOfList(activities);
  const lastActivityPlace = (lastAct && lastAct.place) || getFirstPlaceByPriority(placesPreference);

  if (!lastActivityPlace) return null;

  return {
    ...lastActivityPlace,
    ...RAMQPlaces.get(lastActivityPlace.number)
  };
};

export const getLastPoolUsedInContext = (activities) => {
  const lastAct = getLastActOfList(activities, [isAnAct, isBilledAsPool]);
  if (!lastAct) return null;
  return lastAct.poolNumber || null;
};

export const getLastBillingTypeUsedInContext = (activities) => {
  const lastAct = getLastActOfList(activities);
  if (!lastAct) return null;
  return lastAct.billingType;
};

const hasAtLeastOneCodeInRoleTwoWithExceptions = (activity) => {
  if (!Array.isArray(activity.codes) || activity.codes.length === 0) {
    return false;
  }

  const userSpecialty = selectSpecialty()(getStore().getState());

  return activity.codes.filter(isNotAnException).some((code) => codeIsInRole2(code, activity, userSpecialty));
};

const filterOtherActivitiesAndUnusableValues = (acts) =>
  acts.filter(isAnAct).filter(hasAnEndTime).filter(endTimeIsInSameDay);

const isAnAct = (activity) => activity.type === ACTIVITIES_ACT_TYPE;

const isBilledAsPool = (activity) => [BillingType.POOL, BillingType.END].includes(activity.billingType);

const hasAnEndTime = (activity) => !!activity.end;

const hasNoCode = (act) => !Array.isArray(act.codes) || act.codes.length === 0;

const endTimeIsInSameDay = (activity) => datesAreTheSameDay(moment(activity.date), moment(activity.end));

const hasPainDayExclusiveCode = ({ codes, date }) =>
  codes.some(({ code }) => {
    const ramqCode = RAMQCodes.get(code);

    if (!ramqCode || !RAMQCodes.isCodeEffectiveOn(ramqCode, date)) return false;

    return RAMQCodes.getTimedAttribute(ramqCode, TimedAttributeKey.isPainDayExclusive, date) === true;
  });

const getStartingPointForSurgeon = (acts) => {
  const usableActs = acts.filter((act) => {
    if (isAnAct(act)) return true;
    if (hasNoCode(act)) return true;
    if (act.start) return true;

    return false;
  });

  return last(sortBy(usableActs, 'start'))?.start;
};
