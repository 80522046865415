import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { getRamqNumberOfHoursWorkedFromTimestamps } from '../../../../ramq/ramqCommonUtils';

const { range } = extendMoment(moment as any);

export const CAPQ_CODES = ['078122', '078123', '078300', '078309', '078124', '078129'];

interface Day {
  activityArea: string;
  code: string;
  date: number;
  end: number;
  start: number;
}

enum PartOfDay {
  AM = 'am',
  EVENING = 'soir',
  NIGHT = 'nuit',
  PM = 'pm'
}

interface TimeSlot {
  date: number;
  end: number;
  start: number;
  timeslot: PartOfDay;
}

interface Boundaries {
  lower: number;
  upper: number;
}

const CAPQ_TIME_SLOTS: Array<[PartOfDay, Boundaries]> = [
  [PartOfDay.NIGHT, { lower: 0, upper: 8 }],
  [PartOfDay.AM, { lower: 8, upper: 12 }],
  [PartOfDay.PM, { lower: 12, upper: 16 }],
  [PartOfDay.EVENING, { lower: 16, upper: 24 }],
  [PartOfDay.NIGHT, { lower: 24, upper: 34 }]
];

class CAPQTimeSlotCalculator {
  public calculate(day: Day) {
    return this.computeTimeSlot(day.date, day.start, day.end).map(({ date, end, start, timeslot }) => ({
      details: [
        {
          code: day.code,
          hoursWorked: getRamqNumberOfHoursWorkedFromTimestamps(start, end),
          sector: day.activityArea
        }
      ],
      timePeriod: { date, end, start, timeslot },
      day: moment(start).format('DD'),
      date: moment(start).format('YYYYMMDD')
    }));
  }

  private computeTimeSlot(date: number, start: number, end: number): TimeSlot[] {
    const dayStart = moment(start);
    const dayEnd = moment(end);

    if (dayEnd.isSameOrBefore(dayStart)) {
      dayEnd.add(1, 'day');
    }

    const dayRange = range(dayStart, dayEnd);

    return CAPQ_TIME_SLOTS.reduce((accumulator: TimeSlot[], [key, { lower, upper }]) => {
      const slotLowerBoundDate = moment(date).hour(lower);
      const slotUpperBoundDate = moment(date).hour(upper);
      const slotRange = range(slotLowerBoundDate, slotUpperBoundDate);

      if (!slotRange.overlaps(dayRange)) {
        return accumulator;
      }

      return [
        ...accumulator,
        {
          date,
          end: moment.min([dayEnd, slotUpperBoundDate]).valueOf(),
          start: moment.max([dayStart, slotLowerBoundDate]).valueOf(),
          timeslot: key
        }
      ];
    }, []);
  }
}

export default CAPQTimeSlotCalculator;
