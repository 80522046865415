import { Button } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import User from 'shared/domain/user/User';
import { UserModel } from '../../../../../../shared/domain/authentication/AuthUser';
import deviceDetector from '../../../../../components/utils/DeviceDetector';
import { getDoctorPreferences } from '../../../../../doctorsRegistry/adapters/actions';
import {
  getSpecialtyFavoritesActCodes,
  getSpecialtyPresetActCodes
} from '../../../../../favorite/actCode/adapters/actions';
import {
  getContextElementsPreferences,
  getSpecialtyContextElements
} from '../../../../../favorite/contextElement/adapters/actions';
import {
  getDiagnosticCodesPreferences,
  getSpecialtyDiagnosticCodes
} from '../../../../../favorite/diagnosticCode/adapters/actions';
import UserAPI from '../../../../../infrastructure/api/UserAPI';
import { getActivities } from '../../../../Activities/actions';
import { selectCurrentLoggedUser } from '../../../../Authentication/selectors';
import { getCodesPreferences } from '../../../../CodeSearchDialog/actions';
import { getPlacesPreferences } from '../../../../PlacesPreferencesDialog/actions';
import { getLumpSumsPreferences, setUserInContext, setUserProfileInContext } from '../../../../User/actions';
import { selectUserProfileInContext } from '../../../../User/selectors';

const styles = () =>
  ({
    container: {
      display: 'flex',
      justifyContent: 'end'
    },
    clearImpersonnificationButton: {
      color: '#fff'
    }
  }) as const;

interface Props extends WithStyles<typeof styles> {
  currentUserProfile: User;
  loggedInUser: UserModel;
  setUserIdInContext: (userId: string) => void;
  setUserProfileInContext: (user: any) => void;
}

function ClearImpersonnification({
  classes,
  currentUserProfile,
  loggedInUser,
  setUserIdInContext,
  setUserProfileInContext
}: Props) {
  const clearImpersonnification = async () => {
    setUserIdInContext(loggedInUser.uid);
    setUserProfileInContext(await UserAPI.fetchProfile(loggedInUser.uid));
  };

  if (
    !deviceDetector.isBrowser() ||
    !currentUserProfile ||
    !loggedInUser ||
    currentUserProfile.id === loggedInUser.uid
  ) {
    return null;
  }

  return (
    <Restrict atLeastRole={Role.Admin}>
      <div className={classes.container}>
        <Button className={classes.clearImpersonnificationButton} onClick={clearImpersonnification}>
          Effacer impersonnification
        </Button>
      </div>
    </Restrict>
  );
}

export const mapStateToProps = createStructuredSelector({
  currentUserProfile: selectUserProfileInContext(),
  loggedInUser: selectCurrentLoggedUser()
});

export const mapDispatchToProps = (dispatch) => ({
  setUserIdInContext: (userId) => {
    dispatch(setUserInContext(userId));
  },
  setUserProfileInContext: (user) => {
    dispatch(setUserProfileInContext(user));
    dispatch(getActivities(null));
    dispatch(getPlacesPreferences());
    dispatch(getDoctorPreferences());
    dispatch(getCodesPreferences());
    dispatch(getSpecialtyPresetActCodes());
    dispatch(getSpecialtyFavoritesActCodes());
    dispatch(getSpecialtyDiagnosticCodes());
    dispatch(getSpecialtyContextElements());
    dispatch(getDiagnosticCodesPreferences());
    dispatch(getContextElementsPreferences());
    dispatch(getLumpSumsPreferences());
  }
});

// Since Typescript 5.4 the connect function raise the following error:
// TS2589: Type instantiation is excessively deep and possibly infinite.
// @ts-ignore
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ClearImpersonnification);
