import React from 'react';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import useTranslate from 'application/hooks/use-translate';

interface Props {
  userId: string;
  practiceNumber: string;
}

const SIGN_UP_DETAILS_QUERY = gql`
  query SignUpDetails($practiceNumber: String!, $id: String!) {
    directoryPhysicianByPracticeNumber(practiceNumber: $practiceNumber) {
      id
      practiceNumber
      listedAt
    }

    user(id: $id) {
      id
      practiceNumber
      createdAt
    }
  }
`;

interface SignUpDetails {
  user: {
    id: string;
    practiceNumber: string;
    createdAt: string;
  };

  directoryPhysicianByPracticeNumber: {
    id: string;
    practiceNumber: string;
    listedAt: string;
  } | null;
}

const UserRegistration = ({ userId, practiceNumber }: Props) => {
  const translate = useTranslate('pages.profile.registration');

  const { data, loading } = useQuery<SignUpDetails>(SIGN_UP_DETAILS_QUERY, {
    variables: { id: userId, practiceNumber: practiceNumber.substring(1, 6) }
  });

  if (loading || !data || !data.user) {
    return null;
  }

  const createdAt = moment(data.user.createdAt);
  const listedAt = data?.directoryPhysicianByPracticeNumber?.listedAt
    ? moment(data?.directoryPhysicianByPracticeNumber?.listedAt)
    : null;

  return (
    <div className="flex justify-between">
      <div className="flex gap-2">
        <div>{translate('medyx')}</div>
        <div>{createdAt.format('YYYY-MM-DD')}</div>
      </div>
      <div className="flex gap-2">
        <div>{translate('directoryPhysician')}</div>
        <div>{listedAt ? listedAt.format('YYYY-MM-DD') : translate('directory-unavailable')}</div>
      </div>
    </div>
  );
};

export default UserRegistration;
