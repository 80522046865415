import SpecialtyName from '../../core/doctor/domain/SpecialtyName';
import HOSPITAL_ACTIVITY_AREA_MAP, { ActivityArea } from '../../ramq/domainValues/activityAreas';

export interface VisitCodeOption {
  shortLabel: string;
  label: string;
  value: string;
}

const VISIT_CODE_MAP = {
  [SpecialtyName.PNEUMOLOGIST]: {
    [HOSPITAL_ACTIVITY_AREA_MAP.externalClinic]: [
      { shortLabel: 'CONS', label: 'Consult', value: '9170' },
      { shortLabel: 'VP', label: 'VP', value: '9162' },
      { shortLabel: 'VC', label: 'VC', value: '9164' }
    ],
    [HOSPITAL_ACTIVITY_AREA_MAP.generalCare]: [
      { shortLabel: 'CONS', label: 'Consult', value: '9160' },
      { shortLabel: 'VT', label: 'VT', value: '9094' },
      { shortLabel: 'VT(P)', label: 'VT (pneumo)', value: '15236' },
      { shortLabel: 'VP', label: 'VP', value: '9150' },
      { shortLabel: 'VP(P)', label: 'VP (pneumo)', value: '15235' },
      { shortLabel: 'VC', label: 'VC', value: '9152' },
      { shortLabel: 'VC(P)', label: 'VC (pneumo)', value: '15237' },
      { shortLabel: 'TWE', label: 'Tournée WE', value: '9161' },
      { shortLabel: 'TWE(P)', label: 'Tournée WE (pneumo)', value: '15238' },
      { shortLabel: 'VD', label: 'VD', value: '24' }
    ],
    [HOSPITAL_ACTIVITY_AREA_MAP.emergencyClinic]: [{ shortLabel: 'CONS', label: 'Consult', value: '9108' }]
  },
  [SpecialtyName.INTERNIST]: {
    [HOSPITAL_ACTIVITY_AREA_MAP.externalClinic]: [
      { shortLabel: 'CONS', label: 'Consult', value: '43' },
      { shortLabel: 'VP', label: 'VP', value: '42' },
      { shortLabel: 'VC', label: 'VC', value: '45' },
      { shortLabel: 'SMO', label: 'Suivi + med onco', value: '15403' }
    ],
    [HOSPITAL_ACTIVITY_AREA_MAP.generalCare]: [
      { shortLabel: 'CONS', label: 'Consult', value: '36' },
      { shortLabel: 'VT', label: 'VT', value: '38' },
      { shortLabel: 'VT(MI)', label: 'VT (MI)', value: '15670' },
      { shortLabel: 'VP', label: 'VP', value: '34' },
      { shortLabel: 'VC', label: 'VC', value: '39' },
      { shortLabel: 'TWE', label: 'Tournée WE', value: '41' },
      { shortLabel: 'TWE(MI)', label: 'Tournée WE (MI)', value: '15671' },
      { shortLabel: 'VD', label: 'VD', value: '24' },
      { shortLabel: 'FO', label: 'Forfait', value: '15398' },
      { shortLabel: 'FO(MI)', label: 'Forfait (MI)', value: '15669' }
    ],
    [HOSPITAL_ACTIVITY_AREA_MAP.emergencyClinic]: [{ shortLabel: 'CONS', label: 'Consult', value: '51' }],
    [HOSPITAL_ACTIVITY_AREA_MAP.coronaryUnit]: [
      { shortLabel: 'VP', label: 'VP', value: '28' },
      { shortLabel: 'VP(MI)', label: 'VP (MI)', value: '15672' }
    ]
  },
  [SpecialtyName.GENERAL_SURGEON]: {
    [HOSPITAL_ACTIVITY_AREA_MAP.externalClinic]: [
      { shortLabel: 'CONS', label: 'Consult', value: '9170' },
      { shortLabel: 'VP', label: 'VP', value: '9162' },
      { shortLabel: 'VC', label: 'VC', value: '9164' },
      { shortLabel: 'SM', label: 'Suivi onco', value: '15391' },
      { shortLabel: 'SMO', label: 'Suivi + med onco', value: '15272' }
    ],
    [HOSPITAL_ACTIVITY_AREA_MAP.generalCare]: [
      { shortLabel: 'CONS', label: 'Consult', value: '9160' },
      { shortLabel: 'VP', label: 'VP', value: '9150' },
      { shortLabel: 'VC', label: 'VC', value: '9152' },
      { shortLabel: 'TWE', label: 'Tournée WE', value: '9161' },
      { shortLabel: 'SO', label: 'Suivi onco', value: '15387' }
    ],
    [HOSPITAL_ACTIVITY_AREA_MAP.intensiveCareUnit]: [
      { shortLabel: 'CONS', label: 'Consult', value: '15389' },
      { shortLabel: 'VP', label: 'VP', value: '15388' },
      { shortLabel: 'VC', label: 'VC', value: '15390' }
    ],
    [HOSPITAL_ACTIVITY_AREA_MAP.emergencyClinic]: [
      { shortLabel: 'CONS', label: 'Consult', value: '9160' },
      { shortLabel: 'VP', label: 'VP', value: '9150' },
      { shortLabel: 'VC', label: 'VC', value: '9152' },
      { shortLabel: 'TWE', label: 'Tournée WE', value: '9161' },
      { shortLabel: 'SO', label: 'Suivi onco', value: '15387' }
    ]
  },
  [SpecialtyName.ORTHOPEDIC_SURGEON]: {
    [HOSPITAL_ACTIVITY_AREA_MAP.externalClinic]: [
      { shortLabel: 'CONS', label: 'Consult', value: '9170' },
      { shortLabel: 'VP', label: 'VP', value: '9162' },
      { shortLabel: 'VC', label: 'VC', value: '9164' },
      { shortLabel: 'SO', label: 'Suivi onco', value: '15132' }
    ],
    [HOSPITAL_ACTIVITY_AREA_MAP.generalCare]: [
      { shortLabel: 'CONS', label: 'Consult', value: '9160' },
      { shortLabel: 'VP', label: 'VP', value: '9150' },
      { shortLabel: 'VC', label: 'VC', value: '9152' },
      { shortLabel: 'SO', label: 'Suivi onco', value: '15131' },
      { shortLabel: 'CT', label: 'Clinique tumeur', value: '9168' }
    ],
    [HOSPITAL_ACTIVITY_AREA_MAP.emergencyClinic]: [
      { shortLabel: 'CONS', label: 'Consult', value: '9160' },
      { shortLabel: 'VP', label: 'VP', value: '9150' },
      { shortLabel: 'VC', label: 'VC', value: '9152' },
      { shortLabel: 'SO', label: 'Suivi onco', value: '15131' },
      { shortLabel: 'CT', label: 'Clinique tumeur', value: '9168' }
    ]
  }
};

const CABINET_VISIT_CODE_MAP = {
  [SpecialtyName.PNEUMOLOGIST]: [
    { shortLabel: 'CONS', label: 'Consult', value: '9165' },
    { shortLabel: 'VP', label: 'VP', value: '9127' },
    { shortLabel: 'VC', label: 'VC', value: '9129' }
  ],
  [SpecialtyName.INTERNIST]: [
    { shortLabel: 'CONS', label: 'Consult', value: '31' },
    { shortLabel: 'VP', label: 'VP', value: '30' },
    { shortLabel: 'VC', label: 'VC', value: '33' }
  ],
  [SpecialtyName.GENERAL_SURGEON]: [
    { shortLabel: 'CONS', label: 'Consult', value: '9165' },
    { shortLabel: 'VP', label: 'VP', value: '9127' },
    { shortLabel: 'VC', label: 'VC', value: '9129' }
  ],
  [SpecialtyName.ORTHOPEDIC_SURGEON]: [
    { shortLabel: 'CONS', label: 'Consult', value: '9165' },
    { shortLabel: 'VP', label: 'VP', value: '9127' },
    { shortLabel: 'VC', label: 'VC', value: '9129' }
  ]
};

export const getVisitCodeOptions = (specialty: SpecialtyName, activityArea: ActivityArea): VisitCodeOption[] =>
  VISIT_CODE_MAP[specialty]?.[activityArea] || [];

export const getCabinetVisitCodeOptions = (specialty: SpecialtyName): VisitCodeOption[] =>
  CABINET_VISIT_CODE_MAP[specialty] || [];
