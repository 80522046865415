import React, { useEffect } from 'react';
import { LumpSumDay } from 'shared/domain/activity/lumpSum/model/LumpSum';
import { gql, useQuery } from '@apollo/client';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import moment from 'moment';
import { MixteDay } from 'shared/domain/collection/activity/Mixte.types';
import AlertSeverity from 'app/ui/shared/severity/AlertSeverity';
import Alert from 'app/components/Alert/Alert';
import { FormattedMessage, useIntl } from 'react-intl';
import lumpSumsPresets from 'app/favorite/lumpSumCode/data/lump-sum-presets.json';

interface Props {
  periodId?: string;
  userId: string;
  type: ActivityType;
  days: MixteDay[] | LumpSumDay[];
}

interface CodeSummary {
  code: string;
  durationInMillis: number;
}

interface LumpSumPresetCategory {
  id: string;
  category: string;
  items: LumpSumPreset[];
}

interface LumpSumPreset {
  code: string;
  yearlyMaximumHours?: number;
}

const toRoundedHours = (millis: number) => Math.floor((millis / 1000 / 60 / 60) * 100) / 100;

const calculateRemainingHours = (durationInMillis: number, yearlyMaximumHours?: number) => {
  if (!yearlyMaximumHours) return 0;
  return yearlyMaximumHours - toRoundedHours(durationInMillis);
};

const breakdownWithCurrentPeriod = (breakdown: Map<string, number>, days: MixteDay[] | LumpSumDay[]) => {
  const newBreakdown = new Map(breakdown);
  days.forEach((day) => {
    if (!day.code || !day.start || !day.end) return;
    if (!newBreakdown.has(day.code)) {
      newBreakdown.set(day.code, 0);
    }

    const dayStart = moment(day.start);
    const dayEnd = moment(day.end);

    if (dayEnd.isSameOrBefore(dayStart)) {
      dayEnd.add(1, 'day');
    }

    const dayDiff = dayEnd.diff(dayStart);

    newBreakdown.set(day.code, newBreakdown.get(day.code)! + dayDiff);
  });
  return newBreakdown;
};

const ACTIVITY_DAYS_CODES_QUERY = gql`
  query SearchActivitiesExcludingCurrentPeriod($query: GetActivitiesInput!, $limit: Float!, $offset: Float!) {
    searchActivities(query: $query, limit: $limit, offset: $offset) {
      activities {
        id
        codesSummary {
          code
          durationInMillis
        }
      }
    }
  }
`;

const CodeSumsCurrentYear: React.FunctionComponent<Props> = ({ periodId, userId, type, days }: Props) => {
  const intl = useIntl();
  const [durationByCodes, setDurationByCodes] = React.useState<Map<string, number>>(new Map());
  const [yearlyMaximumHoursByCode] = React.useState<Map<string, number>>(new Map());

  const { data, loading, error } = useQuery<{
    searchActivities: {
      activities: {
        codesSummary: CodeSummary[];
      }[];
    };
  }>(ACTIVITY_DAYS_CODES_QUERY, {
    skip: !navigator.onLine || ![ActivityType.LUMP_SUM].includes(type),
    variables: {
      query: {
        excludedFirebaseIds: [periodId],
        userIds: [userId],
        types: [type],
        startDate: moment().startOf('year')
      },
      limit: 1000,
      offset: 0
    }
  });

  useEffect(() => {
    if (!data || loading || error || !data.searchActivities.activities) {
      setDurationByCodes(new Map());
      return;
    }

    const durationByCodes = data.searchActivities.activities.reduce((acc, activity) => {
      if (!activity.codesSummary) return acc;

      activity.codesSummary.forEach(({ code, durationInMillis }) => {
        if (!days.some((day) => day.start && day.end && day.code === code)) return;

        if (!acc.has(code)) {
          acc.set(code, 0);
        }
        acc.set(code, acc.get(code)! + durationInMillis);
      });
      return acc;
    }, new Map<string, number>());

    const breakdown = breakdownWithCurrentPeriod(durationByCodes, days);
    setDurationByCodes(breakdown);
  }, [data, loading, error, days]);

  useEffect(() => {
    lumpSumsPresets.forEach((category: LumpSumPresetCategory) => {
      category.items.forEach((item: LumpSumPreset) => {
        yearlyMaximumHoursByCode.set(item.code, item.yearlyMaximumHours || 0);
      });
    });
  }, [yearlyMaximumHoursByCode]);

  if (
    !data ||
    !data.searchActivities.activities ||
    durationByCodes.size === 0 ||
    ![ActivityType.LUMP_SUM].includes(type)
  )
    return null;

  return (
    <Alert severity={AlertSeverity.INFO} showIcon title={intl.formatMessage({ id: 'lumpSum.code.hours.title' })}>
      <ul>
        {Array.from(durationByCodes.entries())
          .sort()
          .map(([code, durationInMillis]) => (
            <li key={code}>
              <FormattedMessage
                id="lumpSum.code.hours.current.year"
                values={{
                  code,
                  hours: toRoundedHours(durationInMillis),
                  maximum: yearlyMaximumHoursByCode.get(code),
                  remaining: calculateRemainingHours(durationInMillis, yearlyMaximumHoursByCode.get(code)),
                  b: (chunks) => <strong>{chunks}</strong>
                }}
              />
            </li>
          ))}
      </ul>
    </Alert>
  );
};

export default CodeSumsCurrentYear;
