import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';

import PropTypes from 'prop-types';
import React from 'react';
import { formatUnixToTimeValue } from '../../../../shared/utils/dateTime/dateTimeUtils';
import TimeSelectorDialog from '../../../containers/ActForm/TimeSelectorDialog/TimeSelectorDialog';
import DialogTrigger from '../../Dialogs/DialogTrigger/DialogTrigger';

const styles = () => ({
  timeInputContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  timeInputLabel: {
    top: -5,
    left: 'unset',
    transformOrigin: 'top',
    transform: 'scale(0.75)'
  },
  timeButton: {
    fontSize: '1rem'
  },
  errorTimeHelperText: {
    position: 'absolute',
    bottom: 0
  }
});

function CustomTimePicker({ unixDateToSyncWith, label, input, meta, classes, currentStartTime }) {
  const { value, onChange } = input;
  const { error } = meta;

  return (
    <div className={classes.timeInputContainer}>
      <FormControl className={classes.timeInputContainer}>
        {(value || error) && (
          <InputLabel shrink className={classes.timeInputLabel} error={!!error}>
            {label}
          </InputLabel>
        )}
        <DialogTrigger
          DialogComponent={TimeSelectorDialog}
          onItemSelected={(time) => onChange(time)}
          dialogProps={{
            unixDateToSyncWith,
            startTime: currentStartTime,
            selectedTime: value
          }}
        >
          <Button className={classes.timeButton}>{value ? formatUnixToTimeValue(value) : label}</Button>
        </DialogTrigger>
      </FormControl>
      {error && (
        <FormHelperText error className={classes.errorTimeHelperText}>
          {error}
        </FormHelperText>
      )}
    </div>
  );
}

CustomTimePicker.propTypes = {
  unixDateToSyncWith: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  currentStartTime: PropTypes.number
};

CustomTimePicker.defaultProps = {
  currentStartTime: undefined
};

export default withStyles(styles)(CustomTimePicker);
