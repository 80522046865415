import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import HeaderPortal from '../../../components/Portals/HeaderPortal/HeaderPortal';
import UserAPI from '../../../infrastructure/api/UserAPI';
import { getCodesPreferences } from '../../CodeSearchDialog/actions';
import { getDoctorPreferences } from '../../../doctorsRegistry/adapters/actions';
import { ACTIVITIES_ROUTE, PROFILE_ROUTE, REPORTS_ROUTE, USERS_ROUTE } from '../../Navigation/constants';
import { getPlacesPreferences } from '../../PlacesPreferencesDialog/actions';
import ReportsRoute from '../../ReportsRoute/ReportsRoute';
import { getLumpSumsPreferences, setUserInContext, setUserProfileInContext } from '../../User/actions';
import UserProfile from './Profile/UserProfile';
import UserActivities from './UserActivities/UserActivities';
import {
  getDiagnosticCodesPreferences,
  getSpecialtyDiagnosticCodes
} from '../../../favorite/diagnosticCode/adapters/actions';
import { getSpecialtyFavoritesActCodes, getSpecialtyPresetActCodes } from '../../../favorite/actCode/adapters/actions';
import {
  getContextElementsPreferences,
  getSpecialtyContextElements
} from '../../../favorite/contextElement/adapters/actions';
import User from '../../../../shared/domain/user/User';
import FullScreenLoading from '../../../components/Loadings/FullScreenLoading/FullScreenLoading';
import { selectIsUserProfileReady } from '../../User/selectors';

export const styles = (theme) =>
  ({
    userContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      flex: 1,
      alignItems: 'center'
    },
    navigationButton: {
      height: theme.spacing(6)
    }
  }) as const;

const tabList = ['activities', 'profile', 'reports'];

interface Props extends WithStyles<typeof styles> {
  isUserProfileReady: boolean;
  setUserInContext: (user: User) => void;
}

export const UserRoute: React.FunctionComponent<Props> = ({ classes, isUserProfileReady, setUserInContext }) => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const location = useLocation();

  const tabIndex = useMemo(() => {
    const match = location.pathname.match(/\/users\/[^/]+\/([^/]+)/);
    return match ? tabList.indexOf(match[1]) : -1;
  }, [location.pathname]);

  const goToTab = (event, tabIndex) => {
    navigate({
      pathname: tabList[tabIndex] === 'reports' ? 'reports/statement' : tabList[tabIndex],
      search: location.search
    });
  };

  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    // Make sure the original user is logged in before performing impersonification
    if (!isUserProfileReady || !userId) {
      return;
    }

    setIsFetching(true);

    UserAPI.fetchProfile(userId)
      .then(setUserInContext)
      .catch(() => navigate(USERS_ROUTE))
      .finally(() => setIsFetching(false));
    // navigate is not a dependency because it is a function that never changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserInContext, isUserProfileReady, userId]);

  if (isFetching) return <FullScreenLoading />;

  return (
    <div className={classes.userContainer}>
      <HeaderPortal>
        <Tabs variant="fullWidth" value={tabIndex} onChange={goToTab}>
          <Tab label="Activities" />
          <Tab label="Profile" />
          <Tab label="Rapports" />
        </Tabs>
      </HeaderPortal>
      <Routes>
        <Route path={PROFILE_ROUTE} element={<UserProfile />} />
        <Route path={`${ACTIVITIES_ROUTE}/*`} element={<UserActivities />} />
        <Route path={`${REPORTS_ROUTE}/*`} element={<ReportsRoute />} />
        <Route index element={<Navigate to={`${ACTIVITIES_ROUTE}`} />} />
      </Routes>
    </div>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  setUserInContext: (user) => {
    dispatch(setUserInContext(user.id));
    dispatch(setUserProfileInContext(user));
    dispatch(getPlacesPreferences());
    dispatch(getDoctorPreferences());
    dispatch(getCodesPreferences());
    dispatch(getSpecialtyPresetActCodes());
    dispatch(getSpecialtyFavoritesActCodes());
    dispatch(getSpecialtyDiagnosticCodes());
    dispatch(getSpecialtyContextElements());
    dispatch(getDiagnosticCodesPreferences());
    dispatch(getContextElementsPreferences());
    dispatch(getLumpSumsPreferences());
  }
});

export const mapStateToProps = createStructuredSelector({
  isUserProfileReady: selectIsUserProfileReady()
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(UserRoute);
