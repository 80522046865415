import { ActCode, NatureType } from 'shared/core/activity/domain/Act';
import PlaceDomain from 'shared/domain/place/model/PlaceDomain';
import surgeryNatureType from 'app/codeSpecificLogic/requiredDetails/SurgeryNatureType/surgeryNatureType';
import moment from 'moment-timezone';
import { selectSpecialty } from 'app/containers/User/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import SpecialtyName from 'shared/core/doctor/domain/SpecialtyName';
import { AssociatedField } from '../../requiredDetails/requiredDetail.type';
import Act from '../../../../shared/domain/activity/act/model/Act';

const CODES_THAT_NEEDS_WAITING_FOR_SURGERY = [
  '904',
  '905',
  '906',
  '918',
  '919',
  '920',
  '924',
  '925',
  '930',
  '936',
  '937',
  '938',
  '949',
  '950',
  '953',
  '957',
  '959',
  '960',
  '961',
  '962',
  '963',
  '964',
  '965',
  '966',
  '967',
  '968',
  '969',
  '970',
  '41010'
];

const addSurgeryNatureTypeRequiredDetails = (code: ActCode, act: Act): AssociatedField[][] => {
  const userSpecialty = selectSpecialty()(getStore().getState());

  if (!isSpecialtyAllowed(userSpecialty) || !code.natureType || code.isSupplement || !act.place || !isEffective(act)) {
    return [];
  }

  if (
    ![NatureType.PDT, NatureType.SURGERY].includes(code.natureType) &&
    !CODES_THAT_NEEDS_WAITING_FOR_SURGERY.includes(code.code)
  )
    return [];

  const requiredDetails: AssociatedField[][] = [];

  requiredDetails.push(surgeryNatureType.getAssociatedFields(act.date));

  return requiredDetails;
};

const isEffective = (act: Act): boolean => {
  const startMoment = moment(act.start);
  const startOfDay = moment(act.date);
  if (act.place.billByAct) {
    return startMoment.isBetween(startOfDay.clone().hours(15), startOfDay.clone().hours(19), undefined, '[]');
  }
  const place = PlaceDomain.fromDto(act.place);
  if (place.isWeekEndOrHoliday(act.date)) {
    return startMoment.isBetween(startOfDay.clone().hours(8), startOfDay.clone().hours(24), undefined, '[)');
  }
  return startMoment.isBetween(startOfDay.clone().hours(15), startOfDay.clone().hours(21), undefined, '[]');
};

const isSpecialtyAllowed = (userSpecialty: SpecialtyName): boolean =>
  [
    SpecialtyName.ANESTHESIOLOGIST,
    SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST,
    SpecialtyName.GENERAL_SURGEON,
    SpecialtyName.ORTHOPEDIC_SURGEON
  ].includes(userSpecialty);

export default addSurgeryNatureTypeRequiredDetails;
