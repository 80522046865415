import moment from 'moment-timezone';
import upperFirst from 'lodash/upperFirst';
import { CAPQ_CODES } from 'shared/periods/lumpSum/timeSlot/timeSlotCalculators/CAPQTimeSlotCalculator';

const COMPLEMENTARY_INFORMATION = 'Autorisé à fournir des soins les week-ends et les jours fériés';

export default (documentToSave) => {
  const { complementaryInformation, days = [] } = documentToSave;

  let newComplementaryInformation;

  if (days.length === 0) return documentToSave;

  if (complementaryInformation && complementaryInformation.length > 0) {
    newComplementaryInformation = complementaryInformation.replace(COMPLEMENTARY_INFORMATION, '');
  }

  if (days.some(({ code }) => code === '002064')) {
    newComplementaryInformation = `${COMPLEMENTARY_INFORMATION} ${newComplementaryInformation || ''}`;
  }

  if (newComplementaryInformation && newComplementaryInformation.length === 0) {
    newComplementaryInformation = null;
  }

  days.forEach((day) => {
    const currentDayDescription = upperFirst(moment(day.start).format('dddd D MMMM hh:mm'));

    if (CAPQ_CODES.includes(day.code) && !newComplementaryInformation?.includes(currentDayDescription)) {
      newComplementaryInformation = `${newComplementaryInformation ? `${newComplementaryInformation} ` : ''}${upperFirst(moment(day.start).format('dddd D MMMM HH:mm'))};`;
    }
  });

  if (complementaryInformation !== newComplementaryInformation) {
    return {
      ...documentToSave,
      complementaryInformation: newComplementaryInformation?.trim()
    };
  }

  return documentToSave;
};
