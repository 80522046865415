import { ActOptimisationSpecification } from '../ActOptimisationSpecification';
import ActBillingContext from '../../optimisation/ActBillingContext';
import SpecialtyName from '../../../../core/doctor/domain/SpecialtyName';

export class DoctorSpecialtyIs extends ActOptimisationSpecification {
  // eslint-disable-next-line no-unused-vars
  constructor(private specialty: SpecialtyName) {
    super();
  }

  isSatisfiedBy(context: ActBillingContext): boolean {
    return context.doctor.specialty === this.specialty;
  }
}

export const doctorIsPneumologist = () => new DoctorSpecialtyIs(SpecialtyName.PNEUMOLOGIST);

export const doctorIsAnesthesiologistOrIntensivist = () =>
  new DoctorSpecialtyIs(SpecialtyName.ANESTHESIOLOGIST).or(
    new DoctorSpecialtyIs(SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST)
  );

export const doctorIsInternist = () => new DoctorSpecialtyIs(SpecialtyName.INTERNIST);

export const doctorIsSurgeon = () =>
  new DoctorSpecialtyIs(SpecialtyName.ORTHOPEDIC_SURGEON).or(new DoctorSpecialtyIs(SpecialtyName.GENERAL_SURGEON));
