import { CircularProgress } from '@material-ui/core';
import { get, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PM_PERDIEM } from 'shared/periods/mixte/constants';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import ActivityType from '../../../../shared/core/activity/domain/ActivityType';
import { selectHasMoreActivities, selectIsLoadingMoreActivities } from '../../../containers/Activities/selectors';
import { selectSelectedActivity } from '../../../containers/ActivitiesSearch/selectors';
import FullScreenLoading from '../../Loadings/FullScreenLoading/FullScreenLoading';
import deviceDetector from '../../utils/DeviceDetector';

const isMixteActivity = (activity) => activity.type === ActivityType.MIXTE;
const activitiesWithoutMixte = (activities) => activities.filter((a) => !isMixteActivity(a));

const sortFieldAccordingToActivityType = (activity) => {
  switch (activity.type) {
    case ActivityType.ACT:
      return activity.remp || activity.start;
    case ActivityType.TRAVEL_EXPENSES:
      return activity.travelInformation.startTime;
    case ActivityType.TRAINING:
    case ActivityType.MIXTE:
    case ActivityType.LUMP_SUM:
      return activity.startDate;
    default:
      throw new Error('Unsupported activity type');
  }
};

const renderActivityComponent = (activity, componentsMapping, isSelected) => {
  const ActivityComponent = componentsMapping[activity.type];

  return <ActivityComponent key={activity.key || activity.id} activity={activity} isSelected={isSelected} />;
};

// This will prevent the last activity from being behind the SpeedDial
// We were using a padding value in css before but it was causing a bug on iOS Safari:
// https://github.com/medyxa/medyx/issues/777#issuecomment-466125381
const renderFooter = (footerRef) => <div ref={footerRef} style={{ height: 80, width: '100%' }} />;

const renderActivities = (
  activities,
  componentsMapping,
  EmptyIndicatorComponent,
  highlightSelected,
  selectedActivity,
  emptyDivRef,
  isLoadingMore
) =>
  activities.length > 0 ? (
    <>
      {activities.map((activity) => {
        const isSelected = highlightSelected && activity.id === get(selectedActivity, 'id');
        return renderActivityComponent(activity, componentsMapping, isSelected);
      })}
      {isLoadingMore && <CircularProgress color="primary" size={48} />}
      {renderFooter(emptyDivRef)}
    </>
  ) : (
    <EmptyIndicatorComponent />
  );

export function GroupedByTimeActivities({
  activities,
  mixteOnTop,
  componentsMapping,
  EmptyIndicatorComponent,
  activitiesLoading,
  highlightSelected,
  selectedActivity,
  useCurrentSort,
  isLoadingMore,
  hasMoreActivities,
  hideCanceled
}) {
  let sortedActivities = activities;
  if (!useCurrentSort) {
    const mixteActivities = mixteOnTop ? activities.filter(isMixteActivity) : [];
    mixteActivities.sort((a, b) => a.perdiems.includes(PM_PERDIEM) - b.perdiems.includes(PM_PERDIEM));
    const restOfActivitiesSortByStartTime = mixteOnTop
      ? sortBy(activitiesWithoutMixte(activities), sortFieldAccordingToActivityType)
      : sortBy(activities, sortFieldAccordingToActivityType);
    sortedActivities = [...mixteActivities, ...restOfActivitiesSortByStartTime];
  }
  if (hideCanceled) {
    sortedActivities = sortedActivities.filter((activity) => ActivityStatus.CANCELED !== activity.status);
  }

  const emptyDivRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!deviceDetector.isBrowser()) {
      return;
    }

    const loadMoreCallback = (e) => {
      if (e[0].isIntersecting) {
        if (!activitiesLoading && hasMoreActivities) {
          const latestDate = sortedActivities[sortedActivities.length - 1]?.date;

          const urlSearchParams = new URLSearchParams(location.search);
          urlSearchParams.set('startAfter', latestDate);

          navigate({
            pathname: location.pathname,
            search: urlSearchParams.toString()
          });
        }
      }
    };

    const observer = new IntersectionObserver(loadMoreCallback, { threshold: 0.1 });

    if (emptyDivRef.current) {
      observer.observe(emptyDivRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [
    emptyDivRef,
    sortedActivities,
    activitiesLoading,
    hasMoreActivities,
    navigate,
    location.search,
    location.pathname
  ]);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);

      if (!location.search.includes('startAfter')) {
        return;
      }

      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.delete('startAfter');

      navigate({
        pathname: location.pathname,
        search: urlSearchParams.toString()
      });
    }
  }, [isLoaded, navigate, location]);

  if (activitiesLoading) {
    return <FullScreenLoading parentNotFlex />;
  }

  return renderActivities(
    sortedActivities,
    componentsMapping,
    EmptyIndicatorComponent,
    highlightSelected,
    selectedActivity,
    emptyDivRef,
    isLoadingMore
  );
}

GroupedByTimeActivities.propTypes = {
  useCurrentSort: PropTypes.bool,
  componentsMapping: PropTypes.object.isRequired,
  activities: PropTypes.array.isRequired,
  activitiesLoading: PropTypes.bool.isRequired,
  EmptyIndicatorComponent: PropTypes.func.isRequired,
  mixteOnTop: PropTypes.bool,
  highlightSelected: PropTypes.bool,
  selectedActivity: PropTypes.object,
  isLoadingMore: PropTypes.bool.isRequired,
  hasMoreActivities: PropTypes.bool.isRequired,
  hideCanceled: PropTypes.bool.isRequired
};

GroupedByTimeActivities.defaultProps = {
  useCurrentSort: false,
  mixteOnTop: false,
  highlightSelected: false,
  selectedActivity: undefined
};

export const mapStateToProps = createStructuredSelector({
  selectedActivity: selectSelectedActivity(),
  isLoadingMore: selectIsLoadingMoreActivities(),
  hasMoreActivities: selectHasMoreActivities()
});

export default compose(connect(mapStateToProps))(GroupedByTimeActivities);
