import React, { useCallback, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Place from '../../../shared/domain/place/model/Place';
import { saveFavoritePlace } from '../../containers/PlacesPreferencesDialog/actions';
import isDefined from '../../../shared/utils/isDefined';

export interface Props {
  place: Place;
  // eslint-disable-next-line no-unused-vars
  onSavePlace: (place: Place) => any;
}

export const PlaceDistance: React.FunctionComponent<Props> = ({ place, onSavePlace }) => {
  const [distance, setDistance] = useState<number | undefined>(place.distanceKM);

  const onBlur = useCallback(() => {
    if (!isDefined(distance)) return;

    onSavePlace({ ...place, distanceKM: distance as number });
  }, [place, distance, onSavePlace]);

  const onDistanceChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const newDistance = isDefined(value) ? Number(value) : undefined;
      setDistance(newDistance);
    },
    [setDistance]
  );

  return (
    <TextField
      type="number"
      fullWidth
      placeholder="KM"
      id={`${place.number}-distanceKM`}
      value={distance || ''}
      onChange={onDistanceChange}
      onBlur={onBlur}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return {
    onSavePlace: bindActionCreators(saveFavoritePlace, dispatch)
  };
}

export default connect(undefined, mapDispatchToProps)(PlaceDistance);
