import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ActivityType from '../../../../../../../shared/core/activity/domain/ActivityType';
import { DETAILED_MIXTE_HOURS } from '../../../../../../../userCustomization/featureNames';
import { getFeatureConfig } from '../../../../../../../userCustomization/featureToggle';
import CustomTimePicker from '../../../../../../components/Form/custom/CustomTimePicker';
import FormElement from '../../../../../../components/Form/FormElement/FormElement';
import messages from '../../../../../ActForm/messages';
import PerdiemSelector from '../../shared/PerdiemSelector/PerdiemSelector';

const styles = () => ({
  timeSelectionContainer: {
    flex: '0 1 auto',
    minWidth: 92,
    display: 'flex'
  }
});

const renderMixte = (fieldName, onChange, classes, date) => {
  if (getFeatureConfig(DETAILED_MIXTE_HOURS)) {
    return renderTimePickers(fieldName, onChange, classes, date);
  }

  return <PerdiemSelector fieldName={fieldName} onChange={onChange} />;
};

const renderTimePickers = (fieldName, onChange, classes, date, startDateTime) => (
  <div className={classes.timeSelectionContainer}>
    <FormElement
      className="startTimePicker"
      name={`${fieldName}.start`}
      label={<FormattedMessage {...messages.start} />}
      component={CustomTimePicker}
      onChange={onChange}
      unixDateToSyncWith={date}
    />
    <FormElement
      className="endTimePicker"
      name={`${fieldName}.end`}
      label={<FormattedMessage {...messages.end} />}
      component={CustomTimePicker}
      onChange={onChange}
      unixDateToSyncWith={date}
      currentStartTime={startDateTime}
    />
  </div>
);

function DynamicPeriodTimeSelection({ date, fieldName, onChange, activityType, classes, startDateTime }) {
  return (
    <>
      {activityType === ActivityType.LUMP_SUM && renderTimePickers(fieldName, onChange, classes, date, startDateTime)}
      {activityType === ActivityType.MIXTE && renderMixte(fieldName, onChange, classes, date)}
    </>
  );
}

DynamicPeriodTimeSelection.propTypes = {
  date: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  activityType: PropTypes.oneOf([ActivityType.MIXTE, ActivityType.LUMP_SUM]).isRequired,
  startDateTime: PropTypes.number
};

DynamicPeriodTimeSelection.defaultProps = {
  startDateTime: undefined,
  onChange: () => {}
};

export default withStyles(styles)(DynamicPeriodTimeSelection);
