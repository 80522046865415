import PeriodAmountService, { DayWithComputedAmount, TotalAmountByCode } from '../PeriodAmountService';
import { TrainingDay } from '../../../domain/activity/training/model/Training';
import { computeTrainingAmountForDay } from '../../computeExpectedAmoutForTraining';

class TrainingAmountService extends PeriodAmountService {
  getAmountByDay(): DayWithComputedAmount[] {
    return (this.activity.days as TrainingDay[]).map((day: TrainingDay) => ({
      ...day,
      amount: computeTrainingAmountForDay(day)
    }));
  }

  getTotalAmount(): number {
    return this.getAmountByDay().reduce((acc, { amount }) => acc + amount, 0);
  }

  getTotalAmountByCode(): TotalAmountByCode[] {
    throw new Error('This method is not implemented for training type');
  }
}

export default TrainingAmountService;
