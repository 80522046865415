import React, { useEffect, useState } from 'react';
import FieldContainer from 'application/components/form-controls/field-container';
import TextInput from 'application/components/form-controls/text-input';
import SelectInput from 'application/components/form-controls/select-input';
import useValidatedForm from 'application/hooks/use-validated-form';
import { SectionProps } from 'application/pages/lobby/lobby.signup';
import PracticeNumberInput from 'application/components/form-controls/practice-number-input';
import useTranslate from 'application/hooks/use-translate';
import SpecialtyName from 'shared/core/doctor/domain/SpecialtyName';
import PhoneInput from 'application/components/form-controls/phone-input';
import Container from 'application/components/form-controls/container';
import CheckboxInput from 'application/components/form-controls/checkbox-input';

const Account = ({ userState, onUserChange, onValidate }: SectionProps) => {
  const translate = useTranslate('pages.lobby.signup.section-account');
  const specialtyTranslate = useTranslate('global.specialty');
  const { registerField, isValid } = useValidatedForm();
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  useEffect(() => {
    onValidate(isValid && acceptedTerms);
  }, [isValid, onValidate, acceptedTerms]);

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
  };

  return (
    <>
      <FieldContainer label={translate('title.label')} required>
        <SelectInput
          {...registerField('title')}
          value={userState.title}
          placeholder
          options={[
            { value: 'Dr', label: translate('title.dr') },
            { value: 'Dre', label: translate('title.dre') }
          ]}
          onChange={(title) => onUserChange({ ...userState, title })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('first-name')} required>
        <TextInput
          {...registerField('firstName')}
          value={userState.firstName}
          onChange={(firstName) => onUserChange({ ...userState, firstName })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('last-name')} required>
        <TextInput
          {...registerField('lastName')}
          value={userState.lastName}
          onChange={(lastName) => onUserChange({ ...userState, lastName })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('specialty')} required>
        <SelectInput
          {...registerField('specialty')}
          value={userState.specialty}
          placeholder
          options={[
            { value: SpecialtyName.ANESTHESIOLOGIST, label: specialtyTranslate('anesthesiologist') },
            {
              value: SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST,
              label: specialtyTranslate('anesthesiologistIntensivist')
            },
            { value: SpecialtyName.GENERAL_SURGEON, label: specialtyTranslate('generalSurgeon') },
            { value: SpecialtyName.INTERNIST, label: specialtyTranslate('internist') },
            { value: SpecialtyName.PNEUMOLOGIST, label: specialtyTranslate('pneumologist') }
          ]}
          onChange={(specialty) => onUserChange({ ...userState, specialty })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('practice-number.label')} helper={translate('practice-number.helper')} required>
        <PracticeNumberInput
          {...registerField('practiceNumber')}
          value={userState.practiceNumber}
          onChange={(practiceNumber) => onUserChange({ ...userState, practiceNumber })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('email')} required>
        <TextInput
          {...registerField('email')}
          value={userState.email}
          onChange={(email) => onUserChange({ ...userState, email })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('phone')} required>
        <PhoneInput
          {...registerField('phone')}
          value={userState.phone}
          onChange={(phone) => onUserChange({ ...userState, phone })}
          required
        />
      </FieldContainer>
      <Container>
        <CheckboxInput value={acceptedTerms} onChange={setAcceptedTerms}>
          <>
            {translate('terms.prefix')}
            &nbsp;
            <a
              href="https://www.medyx.com/politique-de-confidentialite"
              target="_blank"
              rel="noreferrer"
              className="underline"
              tabIndex={-1}
              onClick={handleLinkClick}
            >
              {translate('terms.confidentiality')}
            </a>
            &nbsp;
            {translate('terms.and')}
            &nbsp;
            <a
              href="https://www.medyx.com/termes-et-conditions"
              target="_blank"
              rel="noreferrer"
              className="underline"
              tabIndex={-1}
              onClick={handleLinkClick}
            >
              {translate('terms.terms')}
            </a>
          </>
        </CheckboxInput>
      </Container>
    </>
  );
};

export default Account;
