import { find, get, omit, pick } from 'lodash';
import moment from 'moment-timezone';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import Act, { ActCode, PatientContext } from '../../../../shared/domain/activity/act/model/Act';
import { doctorIsAnAnesthesiologist } from '../../../../shared/models/utils/usersUtils';
import { SDIFF } from '../../../../shared/ramq/contextElements/globalContextElements';
import { changeDateWithoutTime, getUnixStartOfDay } from '../../../../shared/utils/dateTime/dateTimeUtils';
import isDefined from '../../../../shared/utils/isDefined';

import UidGeneratorService from '../../../shared/id/UidGeneratorService';
import { PROFESSIONAL_REFERENCE } from '../../../../shared/ramq/requiredDetails/professionalReferenceConstants';
import { FOLLOWUP_TYPE } from '../../../../shared/ramq/requiredDetails/FollowupType';

class ActDuplicationService {
  private static readonly BASIC_FIELDS_IN_ACT = [
    'assets',
    'date',
    'nam',
    'billingType',
    'patientDiagnostic',
    'patientInformation',
    'place',
    'type',
    'userId',
    'poolNumber'
  ];

  private static readonly FULL_FIELDS_IN_ACT = [
    ...ActDuplicationService.BASIC_FIELDS_IN_ACT,
    'activityArea',
    'attachments',
    'codes',
    'contextElements',
    'doctor',
    'end',
    'note',
    'remp',
    'start'
  ];

  private static readonly FIELDS_TO_REMOVE_WHEN_DUPLICATE_ACT_PERFORMED_YESTERDAY = [
    'adminStatus',
    'optimizations',
    'auditInformation',
    'synchronized',
    'forceOptimization',
    'billNumberMap',
    'ramqExchange',
    'lastActiveBill',
    'adminNotes',
    'corrections',
    'computedBill',
    'computedBillRules',
    'xml'
  ];

  private static readonly CODE_DATA_WHITE_LIST = [
    'objectID',
    'code',
    'description',
    'natureType',
    'effectiveStartDate',
    'effectiveEndDate',
    'associatedRoles',
    'roles',
    'rolesMap',
    'measurementElements',
    'permittedAges',
    'isPlaceOnly',
    'placeInformation',
    'isCSST',
    'CSSTInformation',
    'unitsBySpecialty',
    'units',
    'diagnostic',
    'dateHospitalisation',
    'universityNumber',
    'noStudents',
    PROFESSIONAL_REFERENCE,
    FOLLOWUP_TYPE
  ];

  static duplicateForDoctor(act, user): Partial<Act> {
    const fieldsToPick = doctorIsAnAnesthesiologist(user)
      ? ActDuplicationService.BASIC_FIELDS_IN_ACT
      : ActDuplicationService.FULL_FIELDS_IN_ACT;
    const duplicatedInfo: Partial<Act> = pick(act, fieldsToPick);
    const today = moment();

    if (moment(act.date).isSame(today, 'day')) {
      if (duplicatedInfo.contextElements && Array.isArray(duplicatedInfo.contextElements)) {
        if (!duplicatedInfo.contextElements.includes(SDIFF)) {
          duplicatedInfo.contextElements.push(SDIFF);
        }
      } else {
        duplicatedInfo.contextElements = [SDIFF];
      }
    }

    duplicatedInfo.date = getUnixStartOfDay(today);
    duplicatedInfo.status = ActivityStatus.NO_STATUS;
    duplicatedInfo.id = UidGeneratorService.generateActivityUid();
    duplicatedInfo.patientContext = ActDuplicationService.getPatientContext(act);
    duplicatedInfo.codes = ActDuplicationService.duplicateCodeDataWithoutAdditionalDetails(duplicatedInfo.codes);

    return duplicatedInfo;
  }

  static duplicateForAdmin(act): Partial<Act> {
    const duplicatedInfo: Partial<Act> = pick(act, ActDuplicationService.FULL_FIELDS_IN_ACT);

    duplicatedInfo.id = UidGeneratorService.generateActivityUid();
    duplicatedInfo.status = ActivityStatus.NO_STATUS;
    duplicatedInfo.codes = ActDuplicationService.duplicateCodeDataWithoutAdditionalDetails(duplicatedInfo.codes);

    duplicatedInfo.note = `[DUPLICATA] ${get(duplicatedInfo, 'note', '')}`;
    return duplicatedInfo;
  }

  static duplicateToAnotherDay(act, date): Partial<Act & { duplicationProcessDone: boolean }> {
    const duplicatedAct: Partial<Act & { duplicationProcessDone: boolean }> = omit(
      act,
      ActDuplicationService.FIELDS_TO_REMOVE_WHEN_DUPLICATE_ACT_PERFORMED_YESTERDAY
    );

    duplicatedAct.id = UidGeneratorService.generateActivityUid();
    duplicatedAct.status = ActivityStatus.WAITING;
    duplicatedAct.createdOn = moment().valueOf();
    duplicatedAct.duplicationProcessDone = true;
    duplicatedAct.patientContext = ActDuplicationService.getPatientContext(act);
    duplicatedAct.codes = ActDuplicationService.duplicateCodeDataWithoutAdditionalDetails(duplicatedAct.codes);

    duplicatedAct.date = moment(date).startOf('day').valueOf();
    if (duplicatedAct.start) {
      duplicatedAct.start = changeDateWithoutTime(duplicatedAct.start, date).valueOf();
    }
    if (duplicatedAct.end) {
      duplicatedAct.end = changeDateWithoutTime(duplicatedAct.end, date).valueOf();
    }

    return duplicatedAct;
  }

  private static duplicateCodeDataWithoutAdditionalDetails(duplicatedActCodes: ActCode[] = []): ActCode[] {
    return duplicatedActCodes.map((codeData) => pick(codeData, ActDuplicationService.CODE_DATA_WHITE_LIST) as ActCode);
  }

  private static hasDiagnostic(code): boolean {
    return isDefined(code.diagnostic);
  }

  private static hasAdmissionDate(code): boolean {
    return isDefined(code.dateHospitalisation);
  }

  private static getPatientContext(act): PatientContext {
    const patientContext: PatientContext = {};
    const codes = get(act, 'codes', []);
    const admissionDateCode = find(codes, ActDuplicationService.hasAdmissionDate);
    const diagnosticCode = find(codes, ActDuplicationService.hasDiagnostic);

    if (admissionDateCode) {
      patientContext.admissionDate = admissionDateCode.dateHospitalisation;
    }

    if (diagnosticCode) {
      patientContext.diagnostic = diagnosticCode.diagnostic;
    }

    return patientContext;
  }
}

export default ActDuplicationService;
