import { perror } from '../../../utils/plog';

/* eslint-disable no-shadow, no-unused-vars */
enum SpecialtyName {
  ANESTHESIOLOGIST = 'anesthesiologist',
  ANESTHESIOLOGIST_INTENSIVIST = 'anesthesiologistIntensivist',
  GENERAL_SURGEON = 'generalSurgeon',
  INTERNIST = 'internist',
  ORTHOPEDIC_SURGEON = 'orthopedicSurgeon',
  PNEUMOLOGIST = 'pneumologist'
}

export enum FamilyName {
  ANESTHESIOLOGY,
  INTERNIST,
  SURGERY
}

export default SpecialtyName;

export const SPECIALTY_TO_FAMILY = {
  [SpecialtyName.ANESTHESIOLOGIST]: FamilyName.ANESTHESIOLOGY,
  [SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST]: FamilyName.ANESTHESIOLOGY,
  [SpecialtyName.GENERAL_SURGEON]: FamilyName.SURGERY,
  [SpecialtyName.INTERNIST]: FamilyName.INTERNIST,
  [SpecialtyName.ORTHOPEDIC_SURGEON]: FamilyName.SURGERY,
  [SpecialtyName.PNEUMOLOGIST]: FamilyName.INTERNIST
};

export const SPECIALTY_NAME_TO_ID = {
  [SpecialtyName.ANESTHESIOLOGIST]: '03',
  [SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST]: '03',
  [SpecialtyName.GENERAL_SURGEON]: '07',
  [SpecialtyName.INTERNIST]: '18',
  [SpecialtyName.ORTHOPEDIC_SURGEON]: '07',
  [SpecialtyName.PNEUMOLOGIST]: '17'
};

export const getUserSpecialtyId = (specialtyName: SpecialtyName) => {
  const specialtyId = SPECIALTY_NAME_TO_ID[specialtyName];

  if (specialtyId === undefined) {
    perror(`unable to find id for specialty ${specialtyName}`);
  }

  return specialtyId;
};
