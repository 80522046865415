import Range from '../../../../utils/Range/Range';
import ActOptimisationScenario from '../../../../domain/global/optimisation/ActOptimisationScenario/ActOptimisationScenario';
import { addSupplementCodeToMainCodeInAct, AddSupplementCodeToActPayload } from '../AddSupplementCodeToMainCodeInAct';
import patientAgeIs from '../../../../domain/global/specification/PatientAgeIs/PatientAgeIs';
import {
  doctorIsInternist,
  doctorIsPneumologist,
  doctorIsSurgeon
} from '../../../../domain/global/specification/DoctorSpecialtyIs/DoctorSpecialtyIs';
import {
  actContainCode,
  actDoesNotContainCode
} from '../../../../domain/global/specification/ActContainsCode/ActContainsCode';
import { actContainsUrinarySystemCode } from '../../../../domain/global/specification/ActContainsUrinarySystemCode/ActContainsUrinarySystemCode';
import { actContainsMaleGenitaliaCode } from '../../../../domain/global/specification/ActContainsMaleGenitaliaCode/ActContainsMaleGenitaliaCode';
import { actIsNotReplacement } from '../../../../domain/global/specification/ActIsReplacement/ActIsReplacement';
import { actContainsCodeWithRole } from '../../../../domain/global/specification/ActContainsCodeWithRole/ActContainsCodeWithRole';

const scenarios = [
  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('9307')
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('20200'))
        .and(patientAgeIs(new Range.Builder().lessThan(5).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '9307', supplementCode: '20200' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('9307')
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('20201'))
        .and(patientAgeIs(new Range.Builder().greaterThanOrEqual(5).lessThan(18).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '9307', supplementCode: '20201' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('515')
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('20207'))
        .and(patientAgeIs(new Range.Builder().lessThan(16).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '515', supplementCode: '20207' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('30')
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('15393'))
        .and(doctorIsInternist())
        .and(patientAgeIs(new Range.Builder().greaterThanOrEqual(75).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '30', supplementCode: '15393' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('42')
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('15400'))
        .and(doctorIsInternist())
        .and(patientAgeIs(new Range.Builder().greaterThanOrEqual(75).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '42', supplementCode: '15400' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('9127')
        .and(actDoesNotContainCode('9142'))
        .and(doctorIsPneumologist())
        .and(patientAgeIs(new Range.Builder().lessThanOrEqual(18).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '9127', supplementCode: '9142' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('9129')
        .and(actDoesNotContainCode('9142'))
        .and(doctorIsPneumologist())
        .and(patientAgeIs(new Range.Builder().lessThanOrEqual(18).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '9129', supplementCode: '9142' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('9160')
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('15187'))
        .and(doctorIsPneumologist())
        .and(patientAgeIs(new Range.Builder().lessThanOrEqual(18).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '9160', supplementCode: '15187' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('9161')
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('15359'))
        .and(doctorIsPneumologist())
        .and(patientAgeIs(new Range.Builder().lessThanOrEqual(18).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '9161', supplementCode: '15359' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('9162')
        .and(actDoesNotContainCode('9154'))
        .and(doctorIsPneumologist())
        .and(patientAgeIs(new Range.Builder().lessThanOrEqual(18).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '9162', supplementCode: '9154' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('9164')
        .and(actDoesNotContainCode('9154'))
        .and(doctorIsPneumologist())
        .and(patientAgeIs(new Range.Builder().lessThanOrEqual(18).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '9164', supplementCode: '9154' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('9165')
        .and(actDoesNotContainCode('9142'))
        .and(doctorIsPneumologist())
        .and(patientAgeIs(new Range.Builder().lessThanOrEqual(18).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '9165', supplementCode: '9142' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('9170')
        .and(actDoesNotContainCode('9154'))
        .and(doctorIsPneumologist())
        .and(patientAgeIs(new Range.Builder().lessThanOrEqual(18).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '9170', supplementCode: '9154' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('15238')
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('15239'))
        .and(doctorIsPneumologist())
        .and(patientAgeIs(new Range.Builder().lessThanOrEqual(18).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '15238', supplementCode: '15239' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('9108')
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('15695'))
        .and(doctorIsPneumologist())
        .and(patientAgeIs(new Range.Builder().lessThanOrEqual(18).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '9108', supplementCode: '15695' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('9202')
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('9155'))
        .and(doctorIsPneumologist())
        .and(patientAgeIs(new Range.Builder().lessThanOrEqual(18).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '9202', supplementCode: '9155' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('585')
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('20156'))
        .and(patientAgeIs(new Range.Builder().lessThanOrEqual(14).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '585', supplementCode: '20156' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainCode('596')
        .and(actContainsCodeWithRole('596', 1))
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('20595'))
        .and(patientAgeIs(new Range.Builder().lessThanOrEqual(14).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: '596', supplementCode: '20595' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainsUrinarySystemCode()
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('6087'))
        .and(doctorIsSurgeon())
        .and(patientAgeIs(new Range.Builder().lessThan(2).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: 'code urinaire', supplementCode: '6087' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainsUrinarySystemCode()
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('6087'))
        .and(doctorIsSurgeon())
        .and(patientAgeIs(new Range.Builder().greaterThanOrEqual(75).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: 'code urinaire', supplementCode: '6087' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainsMaleGenitaliaCode()
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('6094'))
        .and(doctorIsSurgeon())
        .and(patientAgeIs(new Range.Builder().lessThan(2).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: 'code appareil génital male', supplementCode: '6094' })),

  new ActOptimisationScenario<AddSupplementCodeToActPayload>()
    .when(
      actContainsMaleGenitaliaCode()
        .and(actIsNotReplacement())
        .and(actDoesNotContainCode('6094'))
        .and(doctorIsSurgeon())
        .and(patientAgeIs(new Range.Builder().greaterThanOrEqual(75).build()))
    )
    .then(addSupplementCodeToMainCodeInAct({ mainCode: 'code appareil génital male', supplementCode: '6094' }))
];

export default scenarios;
