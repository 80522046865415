import React from 'react';
import HybridListSelector from '../../../components/Form/ListSelector/HybridListSelector/HybridListSelector';
import SpecialtyName from '../../../../shared/core/doctor/domain/SpecialtyName';

export interface Props {
  specialties: SpecialtyName[];
  // eslint-disable-next-line no-unused-vars
  onChange: (specialties: SpecialtyName[]) => void;
}

const SPECIALTY_CHOICES = [
  {
    label: 'Anesthésiologiste',
    value: SpecialtyName.ANESTHESIOLOGIST
  },
  {
    label: 'Anesthésiologiste-Intensiviste',
    value: SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST
  },
  {
    label: 'Interniste',
    value: SpecialtyName.INTERNIST
  },
  {
    label: 'Pneumologue',
    value: SpecialtyName.PNEUMOLOGIST
  },
  {
    label: 'Chirurgien général',
    value: SpecialtyName.GENERAL_SURGEON
  },
  {
    label: 'Chirurgien orthopédique',
    value: SpecialtyName.ORTHOPEDIC_SURGEON
  }
];

const DoctorSpecialtyMultiSelector: React.FunctionComponent<Props> = (props: Props) => {
  const { specialties, onChange } = props;

  return (
    <HybridListSelector
      multiple
      placeholder="Choisir une/des spécialité(s)"
      input={{
        onChange: (evt) => onChange(evt.target.value),
        value: specialties
      }}
      values={SPECIALTY_CHOICES}
    />
  );
};

export default DoctorSpecialtyMultiSelector;
