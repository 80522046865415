import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { adjustUnixWithTimeObject } from '../../../../shared/utils/dateTime/dateTimeUtils';
import isDefined from '../../../../shared/utils/isDefined';
import HourMinuteSelector from '../HourMinuteSelector/HourMinuteSelector';

export const styles = (theme) => ({
  timeContainer: {
    display: 'flex',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(3)
  }
});

export class TimeSelectorDialog extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.closeDialog = this.closeDialog.bind(this);
    this.eraseCurrentSelectedTime = this.eraseCurrentSelectedTime.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  closeDialog() {
    this.props.onClose();
  }

  eraseCurrentSelectedTime() {
    this.props.onItemSelected(null);
    this.props.onClose();
  }

  handleOnChange({ hour, minute }) {
    const { startTime } = this.props;
    let { unixDateToSyncWith } = this.props;

    const nextTime = moment(`${hour}:${minute}`, 'kk:mm');

    if (isDefined(startTime)) {
      const referenceTime = moment(startTime).format('kk:mm');

      if (moment(referenceTime, 'kk:mm').isSameOrAfter(nextTime)) {
        unixDateToSyncWith = moment(unixDateToSyncWith).add(1, 'days').valueOf();
      }
    }

    const adjustedTimestamp = adjustUnixWithTimeObject(unixDateToSyncWith, { hour, minutes: minute });

    this.props.onItemSelected(adjustedTimestamp);
    this.props.onClose();
  }

  render() {
    const { open, selectedTime, maxHour, minutesPrecision, numberRows, classes } = this.props;
    return (
      <Dialog open={open}>
        <DialogContent classes={{ root: classes.timeContainer }}>
          <HourMinuteSelector
            maxHour={maxHour}
            minutesPrecision={minutesPrecision}
            numberRows={numberRows}
            onChange={this.handleOnChange}
          />
        </DialogContent>
        <DialogActions>
          {selectedTime && (
            <Button onClick={this.eraseCurrentSelectedTime} color="primary">
              <FormattedMessage id="time-selector-dialog.erase" />
            </Button>
          )}
          <Button onClick={this.closeDialog} color="primary">
            <FormattedMessage id="time-selector-dialog.cancel" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

TimeSelectorDialog.propTypes = {
  maxHour: PropTypes.number,
  minutesPrecision: PropTypes.number,
  numberRows: PropTypes.number,
  unixDateToSyncWith: PropTypes.node.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedTime: PropTypes.oneOfType([
    PropTypes.string, // Redux form field empty value = ""
    PropTypes.number
  ]),
  startTime: PropTypes.node
};

TimeSelectorDialog.defaultProps = {
  selectedTime: undefined,
  startTime: undefined,
  maxHour: 23,
  minutesPrecision: 5,
  numberRows: 6
};

export default withStyles(styles)(TimeSelectorDialog);
